/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminCancelVisitDto } from '../model';
// @ts-ignore
import { DoctorVisitSlotDto } from '../model';
// @ts-ignore
import { ListResponseDtoListVisitDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
// @ts-ignore
import { VisitChangeTypeResult } from '../model';
// @ts-ignore
import { VisitDescriptionUpdateDto } from '../model';
// @ts-ignore
import { VisitDto } from '../model';
/**
 * AdminVisitApiControllerApi - axios parameter creator
 * @export
 */
export const AdminVisitApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdminCancelVisitDto} adminCancelVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVisit1: async (adminCancelVisitDto: AdminCancelVisitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCancelVisitDto' is not null or undefined
            assertParamExists('cancelVisit1', 'adminCancelVisitDto', adminCancelVisitDto)
            const localVarPath = `/api/admin/visit/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCancelVisitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeVisitType: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('changeVisitType', 'visitId', visitId)
            const localVarPath = `/api/admin/visit/change-type/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorAllVisits: async (doctorId: number, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorAllVisits', 'doctorId', doctorId)
            const localVarPath = `/api/admin/visit/doctor/{doctorId}/all`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [patientSearchPhrase] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {Pageable} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsVisits1: async (doctorId: number, patientSearchPhrase?: string, dateFrom?: string, dateTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, pageable?: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorsVisits1', 'doctorId', doctorId)
            const localVarPath = `/api/admin/visit/doctor/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (patientSearchPhrase !== undefined) {
                localVarQueryParameter['patientSearchPhrase'] = patientSearchPhrase;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (visitTypes) {
                localVarQueryParameter['visitTypes'] = visitTypes;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsStartingBetween: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/visit/starting/between`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsToMove: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/visit/to-move`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveVisit: async (doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorVisitSlotDto' is not null or undefined
            assertParamExists('moveVisit', 'doctorVisitSlotDto', doctorVisitSlotDto)
            const localVarPath = `/api/admin/visit/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorVisitSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreFromToMove: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('restoreFromToMove', 'visitId', visitId)
            const localVarPath = `/api/admin/visit/restore-from-to-move/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {'WAITING_FOR_PAYMENT' | 'PAYMENT_RECEIVED'} paymentMarking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPaymentMarking: async (visitId: number, paymentMarking: 'WAITING_FOR_PAYMENT' | 'PAYMENT_RECEIVED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('setPaymentMarking', 'visitId', visitId)
            // verify required parameter 'paymentMarking' is not null or undefined
            assertParamExists('setPaymentMarking', 'paymentMarking', paymentMarking)
            const localVarPath = `/api/admin/visit/mark-paid/{visitId}`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentMarking !== undefined) {
                localVarQueryParameter['paymentMarking'] = paymentMarking;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVisitToMove1: async (visitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('setVisitToMove1', 'visitId', visitId)
            const localVarPath = `/api/admin/visit/{visitId}/to-move`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} visitId 
         * @param {VisitDescriptionUpdateDto} visitDescriptionUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitDescription: async (visitId: number, visitDescriptionUpdateDto: VisitDescriptionUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitId' is not null or undefined
            assertParamExists('updateVisitDescription', 'visitId', visitId)
            // verify required parameter 'visitDescriptionUpdateDto' is not null or undefined
            assertParamExists('updateVisitDescription', 'visitDescriptionUpdateDto', visitDescriptionUpdateDto)
            const localVarPath = `/api/admin/visit/{visitId}/description/`
                .replace(`{${"visitId"}}`, encodeURIComponent(String(visitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitDescriptionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminVisitApiControllerApi - functional programming interface
 * @export
 */
export const AdminVisitApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminVisitApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdminCancelVisitDto} adminCancelVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelVisit1(adminCancelVisitDto: AdminCancelVisitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelVisit1(adminCancelVisitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeVisitType(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<VisitChangeTypeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeVisitType(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorAllVisits(doctorId: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<VisitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorAllVisits(doctorId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [patientSearchPhrase] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {Pageable} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorsVisits1(doctorId: number, patientSearchPhrase?: string, dateFrom?: string, dateTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, pageable?: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ListResponseDtoListVisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorsVisits1(doctorId, patientSearchPhrase, dateFrom, dateTo, visitTypes, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitsStartingBetween(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<VisitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitsStartingBetween(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitsToMove(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<VisitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitsToMove(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveVisit(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveVisit(doctorVisitSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreFromToMove(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreFromToMove(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {'WAITING_FOR_PAYMENT' | 'PAYMENT_RECEIVED'} paymentMarking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPaymentMarking(visitId: number, paymentMarking: 'WAITING_FOR_PAYMENT' | 'PAYMENT_RECEIVED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPaymentMarking(visitId, paymentMarking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setVisitToMove1(visitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setVisitToMove1(visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} visitId 
         * @param {VisitDescriptionUpdateDto} visitDescriptionUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVisitDescription(visitId: number, visitDescriptionUpdateDto: VisitDescriptionUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVisitDescription(visitId, visitDescriptionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminVisitApiControllerApi - factory interface
 * @export
 */
export const AdminVisitApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminVisitApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AdminCancelVisitDto} adminCancelVisitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelVisit1(adminCancelVisitDto: AdminCancelVisitDto, options?: any): Promise<boolean> {
            return localVarFp.cancelVisit1(adminCancelVisitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeVisitType(visitId: number, options?: any): Promise<VisitChangeTypeResult> {
            return localVarFp.changeVisitType(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorAllVisits(doctorId: number, dateFrom?: string, dateTo?: string, options?: any): Promise<Array<VisitDto>> {
            return localVarFp.getDoctorAllVisits(doctorId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {string} [patientSearchPhrase] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
         * @param {Pageable} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsVisits1(doctorId: number, patientSearchPhrase?: string, dateFrom?: string, dateTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, pageable?: Pageable, options?: any): Promise<ListResponseDtoListVisitDto> {
            return localVarFp.getDoctorsVisits1(doctorId, patientSearchPhrase, dateFrom, dateTo, visitTypes, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsStartingBetween(dateFrom?: string, dateTo?: string, options?: any): Promise<Array<VisitDto>> {
            return localVarFp.getVisitsStartingBetween(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsToMove(options?: any): Promise<Array<VisitDto>> {
            return localVarFp.getVisitsToMove(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveVisit(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: any): Promise<void> {
            return localVarFp.moveVisit(doctorVisitSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreFromToMove(visitId: number, options?: any): Promise<void> {
            return localVarFp.restoreFromToMove(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {'WAITING_FOR_PAYMENT' | 'PAYMENT_RECEIVED'} paymentMarking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPaymentMarking(visitId: number, paymentMarking: 'WAITING_FOR_PAYMENT' | 'PAYMENT_RECEIVED', options?: any): Promise<void> {
            return localVarFp.setPaymentMarking(visitId, paymentMarking, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVisitToMove1(visitId: number, options?: any): Promise<void> {
            return localVarFp.setVisitToMove1(visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} visitId 
         * @param {VisitDescriptionUpdateDto} visitDescriptionUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisitDescription(visitId: number, visitDescriptionUpdateDto: VisitDescriptionUpdateDto, options?: any): Promise<void> {
            return localVarFp.updateVisitDescription(visitId, visitDescriptionUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminVisitApiControllerApi - object-oriented interface
 * @export
 * @class AdminVisitApiControllerApi
 * @extends {BaseAPI}
 */
export class AdminVisitApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {AdminCancelVisitDto} adminCancelVisitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public cancelVisit1(adminCancelVisitDto: AdminCancelVisitDto, options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).cancelVisit1(adminCancelVisitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public changeVisitType(visitId: number, options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).changeVisitType(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public getDoctorAllVisits(doctorId: number, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).getDoctorAllVisits(doctorId, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {string} [patientSearchPhrase] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>} [visitTypes] 
     * @param {Pageable} [pageable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public getDoctorsVisits1(doctorId: number, patientSearchPhrase?: string, dateFrom?: string, dateTo?: string, visitTypes?: Array<'REMOTE' | 'STATIONARY' | 'ABSENT'>, pageable?: Pageable, options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).getDoctorsVisits1(doctorId, patientSearchPhrase, dateFrom, dateTo, visitTypes, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public getVisitsStartingBetween(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).getVisitsStartingBetween(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public getVisitsToMove(options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).getVisitsToMove(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DoctorVisitSlotDto>} doctorVisitSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public moveVisit(doctorVisitSlotDto: Array<DoctorVisitSlotDto>, options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).moveVisit(doctorVisitSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public restoreFromToMove(visitId: number, options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).restoreFromToMove(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {'WAITING_FOR_PAYMENT' | 'PAYMENT_RECEIVED'} paymentMarking 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public setPaymentMarking(visitId: number, paymentMarking: 'WAITING_FOR_PAYMENT' | 'PAYMENT_RECEIVED', options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).setPaymentMarking(visitId, paymentMarking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public setVisitToMove1(visitId: number, options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).setVisitToMove1(visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} visitId 
     * @param {VisitDescriptionUpdateDto} visitDescriptionUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminVisitApiControllerApi
     */
    public updateVisitDescription(visitId: number, visitDescriptionUpdateDto: VisitDescriptionUpdateDto, options?: AxiosRequestConfig) {
        return AdminVisitApiControllerApiFp(this.configuration).updateVisitDescription(visitId, visitDescriptionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}
